.marker:hover {
  -webkit-animation: shake .5s ease-in-out;
  animation: shake .5s ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}


@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
  }

  20% {
    -webkit-transform: translateX(-3px);
  }

  40% {
    -webkit-transform: translateX(3px);
  }

  60% {
    -webkit-transform: translateX(-3px);
  }

  80% {
    -webkit-transform: translateX(3px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-3px);
  }

  40% {
    transform: translateX(3px);
  }

  60% {
    transform: translateX(-3px);
  }

  80% {
    transform: translateX(3px);
  }

  100% {
    transform: translateX(0);
  }
}