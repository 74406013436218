/* DashboardComponent's private CSS styles */
.img-center {
  width: 100px;
  height: 100px;
  padding: 5px;
  object-fit: contain;
  vertical-align: middle;
  cursor: pointer;

  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

#custom-search-input {
  padding: 3px;
  border: solid 1px #e4e4e4;
  border-radius: 6px;
  background-color: #fff;
  margin: 0 10px 0 10px;
}

#custom-search-input input {
  border: 0;
  box-shadow: none;
}

#custom-search-input button {
  margin: 2px 0 0 2px;
  background: none;
  box-shadow: none;
  border: 0;
  color: #666666;
  padding: 0 8px 0 10px;
  border-left: solid 1px #ccc;
  width: 100%;
}

#custom-search-input button:hover {
  border: 0;
  box-shadow: none;
  border-left: solid 1px #ccc;
}

#custom-search-input .fa-search {
  font-size: 20px;
  width: 100%;
  text-align: center;
  /* position: absolute;
  top: 0;
  left: 10; */
  color: #666666;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 50;
}

.company-title {
  color: black;
}

.search-result {
  color: #2f5ddb;
}

.circle-icon {
  font-size: 12px;
  color: #f1f1f1;
  position: absolute;
  right: 30%;
  bottom: 22%;

  background: #ffc0c052;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  vertical-align: middle;
  padding: 5px;
}

.content {
  margin: 10px;
}

.address {
  font-size: 15px;
  color: rgb(131, 128, 128);
}

.cover-item {
  border: 2px solid violet;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px;
}

label {
  font-weight: 700;
  font-size: 15px;
}

.search-input {
  border-left-color: #2f5ddb;
  border-top-color: #2f5ddb;
  border-bottom-color: #2f5ddb;
  padding-right: 0;
}

.search-input::placeholder {
  font-size: 15px;
  color: #2f5ddb;
  opacity: 1;
}

.view-details {
  text-decoration: none;
  border: 1px solid #777777;
  border-radius: 8px;
  overflow: hidden;
  color: #2f5ddb;
  font-size: 15px;
  padding: 5px 10px 5px 10px;
}

.view-details:hover {
  font-size: 15px;
  background-color: #2f5ddb;
  color: #f1f1f1;
}

.btn-default {
  background-color: #2f5ddb;
}

.fa-search {
  color: white;
}

.btn-icon {
  background-color: DodgerBlue; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px;
}

.bg-image {
  /* The image used */
  background-image: url('https://static.vecteezy.com/system/resources/previews/000/153/588/original/vector-roadmap-location-map.jpg');

  /* Add the blur effect */
  filter: blur(2px);
  -webkit-filter: blur(2px);

  /* Full height */
  height: 400px;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Position text in the middle of the page/image */
.page-title {
  color: #f1f1f1;
  font-weight: 600;
  padding: 10px;
}

.bg-text {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/opacity/see-through */
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
}

.item-row {
  padding: 10px 0px 10px 0px;
  margin: 10px 5px 15px 5px;
}

.item-img {
  width: 100%;
  height: 200px;
}


