/* Enrol-Form */
.shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

label > span {
  color: red;
  padding-right: 3px;
}

h3 {
  font-size: 16px;
  font-weight: 600;
}

h5 {
  font-size: 25px;
  font-weight: 600;
}

h6 {
  font-size: 18px;
  font-weight: 400;
}

.thumbnail-fit {
  border-radius: 5px;
}

.image-gallery {
  /* padding: 10px; */
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgb(242, 245, 253);
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  object-fit: contain;
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}

.form-check-input {
  margin-top: 0.4rem;
}

.form-control .is-invalid {
  background-position: center right calc(0.375em + 0.1875rem);
}

.form-control .is-invalid > .no-icon {
  background-image: none;
}

.address {
  font-size: 15px;
  color: rgb(204, 204, 204);
}

.check-label {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
}

.preview-frame .file-name,
.fileupload > label,
.contact-label,
.form-group label,
.custom-select {
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: #ced4da;
  line-height: 1.5;
  padding: 3px 5px 4px 2px;
  display: inline-block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

select .form-control,
option,
textarea::placeholder,
input::placeholder {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
}

select#fixed {
  -webkit-appearance: none;
}

.language-bar {
  position: fixed;
  top: 80%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 4px;
  background: #ec7993;
  right: 30px;
  z-index: 999;
}

.language-bar .language {
  padding: 15px 16px;
  cursor: pointer;
}

a.back-to-top-icon {
  background: #e01f4b;
  color: #fff;
  padding: 15px 16px;
}

.language-bar a.back-to-top-icon {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.language-bar a {
  display: block;
  text-align: center;
  transition: all 0.3s ease;
  color: #fff;
  font-size: 20px;
}

/* File Upload */
.fileinput-button,
.fileupload .btn-default.fileinput-button {
  width: 100%;
  padding: 20px 15px;
  border-radius: 5px;
  border-color: #dddddd !important;
  background-color: transparent;
  cursor: default;
  border: solid 1px;
}

.fileupload .outline-circle {
  border: 3px solid #f4f4f4;
  padding: 8px 5px 15px 15px;
  /* display: inline-block; */
  border-radius: 50%;
}

.fileupload .border-circle {
  color: #185adb;
  border: 5px solid #cfcfcf;
  padding: 30px;
  border-radius: 50%;

  font-size: 20px;
}

.fileupload .fileinput-button:active {
  border-color: #dddddd;
  box-shadow: none;
}
.fileupload .fileinput-button::before {
  /* content: '\f0ef'; */
  display: block;
  padding: 5px 0;
  /* font: normal normal normal 40px/1 FontAwesome; */
  /* color: #b1b5cc; */
}

.fileupload .fileinput-button .upload::before {
  content: 'Drop files to upload or';
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #797979;
}

.fileupload .fileinput-button .loading::before {
  content: 'Uploading...';
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #797979;
}

.fileupload .fileinput-button span {
  display: none;
}
.fileupload .fileinput-button input::before {
  content: 'Choose File';
  padding-right: 30px;
}
.fileupload .fileinput-button input {
  margin: 10px auto;
  padding-bottom: 30px;
}

.fileupload .fileinput-button input {
  width: 120px;
  display: block;
  margin: 10px auto;
  padding: 0 20px;
  border-radius: 25px;
  background-color: #1d59df;
  color: white;
  cursor: pointer;
}

.form-check-label {
  margin-bottom: 0;
  vertical-align: top;
}

.btn-default.fileinput-button:hover,
.btn-default.fileinput-button:active,
.btn-default.fileinput-button:focus {
  background-color: transparent !important;
}

/* Preview-image */
.preview-frame {
  margin: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  padding: 10px;
  float: left;
  text-align: center;
}

.preview-frame img {
  width: 150px;
  height: 150px;
  margin: 10px;
}

.preview-frame .pdf-icon {
  color: red;
  display: inline-block;
  margin-top: 8px;
  font-size: 40px;
  position: relative;
  left: 7px;
}

.preview-frame .file-pdf {
  display: inline-block;
  margin: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  border-radius: 50%;
  padding: 20px;
  width: 100px;
  height: 100px;
}

.preview-frame .file-caption {
  display: block;
  text-align: center;
  padding-top: 4px;
  font-size: 11px;
  color: #777;
  margin-bottom: 20px;
}

.preview-frame .file-name {
  display: block;
  text-align: center;
  padding-top: 4px;
  color: #777;
  margin-bottom: 10px;
}

.preview-frame .file-info {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  height: 15px;
  margin: auto;
}

.preview-frame .remove-icon {
  color: red;
  float: right;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 16px;
  height: 16px;
  font-size: 20px;
}

h3 > .remove-icon {
  color: red;
  float: right;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 16px;
  height: 16px;
  font-size: 20px;
}

.btn-next {
  /* display: block; */
  color: #fff;
  background-color: #185adb;
  border-radius: 5px;
  border-width: 1px;
  border-color: #1d59df;
  overflow: hidden;
  margin: 10px;

  /* Text */
  font-size: 15px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: capitalize;
  padding: 5px 45px 5px 45px;
  transition: all 0.5s ease 0s;
}

.btn-next:hover {
  background-color: #fff;
  color: #1d59df;
  text-decoration: none;
}

.btn-prev {
  /* display: block; */
  color: #1d59df;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  border-width: 1px;
  border-color: #1d59df;
  margin: 10px;

  /* Text */
  font-size: 15px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 45px 5px 45px;
  transition: all 0.5s ease 0s;
}

.btn-prev:hover {
  background-color: #1d59df;
  color: #fff;
  text-decoration: none;
}

/* Enrol-Form-Two */

.btn-load {
  background-color: #4caf50; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
}

/* Enrol-Form Done */
.danger {
  color: red;
  font-size: 15px;
}

.wrapper {
  margin-left: 1em;
  margin-right: 1em;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 1em;
  grid-column-gap: 1em;

  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 12px; */
}

.address {
  font-size: 15px;
  color: rgb(204, 204, 204);
}

.tncLink {
  color: #1d59df;
}

.tncLink :hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .indicators-size {
    width: 75px;
    height: 100px;
  }
}



/* Resolution  */
@media (max-width: 768px) {
  
}

@media (max-width: 576px) {
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  h3 {
    font-size: 14px;
  }

  h5 {
    font-size: 19px;
  }

  h6 {
    font-size: 13px;
  }

  p,
  .fileupload > label,
  .contact-label,
  .form-group label,
  select,
  option,
  textarea::placeholder,
  input::placeholder {
    font-size: 12px;
  }
}
