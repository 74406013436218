/* .shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
} */

.img-logo {
  width: 100px;
  height: 100px;
  padding: 5px;
  object-fit: contain;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.center-name{
  font-size: 1.5em;
}

h5 {
  font-size: 19px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}

.address {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  color: #8e8e8e;
}

@media only screen and(max-width: 760px) {
  img {
    margin: 0px 0px 0px 15px;
  }
}

@media only screen and (max-width: 576px) {
  h5 {
    font-size: 18px;
  }

  h3 {
    font-size: 17px;
  }

  p {
    font-size: 12px;
  }

  label {
    font-size: 13px;
  }
}
