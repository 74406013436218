/* Carousel Image */
.indicators-size {
  width: 100px;
  height: 150px;
  border-radius: 8px;
}

.custom-caption p {
  color: white;
  background-color: #000;
  opacity: 0.75;
  padding: 5px;
  border-radius: 5px;
  width: 60px;
  position: absolute;
  left: 45%;
  bottom: 5px;
  /* margin-left: 350px;
  margin-right: 50px; */
}

#center-info {
  width: 100%;
}


.center-subtitle{
  font-size: 1.25em;;
}

.img-border {
  /* display: block;
  opacity: 0.5; */
  border: 3px solid #c5c5c5;
  border-radius: 8px;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 35rem;
}

.carousel-item img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 35rem;
  object-fit: contain;
}

#carousel-example-generic {
  margin: 20px auto;
  width: 100%;
}

#carousel-custom {
  margin: 20px auto;
  width: 100%;
}

#carousel-custom .carousel-indicators {
  margin: 10px 0 0;
  overflow: auto;
  position: static;
  text-align: left;
  white-space: nowrap;
  width: auto;
  overflow: hidden;
}

#carousel-custom .carousel-indicators li {
  background-color: transparent;
  -webkit-border-radius: 0;
  border-radius: 0;
  display: inline-block;
  height: auto;
  margin: 0px 10px 0px 10px !important;
  width: auto;
}
#carousel-custom .carousel-indicators li img {
  display: block;
  opacity: 0.5;
  border: 1px solid #9b9b9b;
}

#carousel-custom .carousel-indicators li.active img {
  opacity: 1;
}

#carousel-custom .carousel-indicators li:hover img {
  opacity: 0.75;
}

.carousel-indicators li img {
  height: 100%;
  width: 70px;
}

#carousel-custom .circular-control {
  background-color: #9b9b9be5;
  border-radius: 50%;
  max-width: 75px;
  max-height: 75px;
  position: absolute;
  top: 40%;
}

#location {
  color: '#007bff';
  margin-right: 8px;
}

#location:hover {
  color: '#ffffff';
}

@media (max-width: 768px) {
  .indicators-size {
    width: 75px;
    height: 100px;
  }
}

@media (max-width: 576px) {
  .indicators-size {
    width: 60px;
    height: 90px;
  }
}

.empty {
  align-content: center;
}

.avatar {
  border-radius: 50%;
  border-width: 1;
  border-color: black;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

i {
  margin-top: 10px;
  margin-right: 10px;
  font-size: 30px;
}

.image-row {
  padding: 15px;
  /* align-self: center;
  justify-content: center; */
}

.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
}

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}
