a {
  font-size: 15px;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

.download > img{
  max-width: 75%;
}

.container {
  max-width: 1266px !important;
  width: 100% !important;
  overflow: hidden;
}

a.logo {
  cursor: pointer;
  display: block;
  text-decoration: none;
}
a .logo:hover {
  text-decoration: none;
}

a.logo img.logo-img {
  max-width: 40px;
  margin-right: 15px;
}
a.logo img.logo-word {
  max-width: 117px;
}

section.temp-section ul.apps-icon {
  padding-left: 0;
}

.menu-row ul {
  padding: 0;
}
.menu-row ul li {
  padding: 10px 0;
  list-style-type: none;
}
.menu-row ul li.title-header {
  color: #fc3868;
  list-style-type: none;
}
.menu-row a {
  color: #fff;
}
.menu-row a:hover {
  text-decoration: none;
  color: #fff;
  background-color: #fc3868;
  padding: 3px;
}
.menu-row a.download:hover {
  text-decoration: none;
  background-color: transparent;
  padding: 0;
}

footer {
  background-color: #1e1d1e;
  padding: 60px 0 30px;
  color: #fff;
}
footer ul.apps-icon,
section.temp-section ul.apps-icon {
  margin: 0;
}
footer ul.apps-icon li,
section.temp-section ul.apps-icon li {
  display: inline-block;
  padding: 0 25px;
}
footer ul.apps-icon li:last-child {
  padding-right: 0;
}
footer .menu-row {
  padding: 70px 30px 60px;
}
footer .menu-row a:hover {
  text-decoration: none;
  color: #fc3868 !important;
  background-color: transparent !important;
  padding: 0;
}
footer ul.footer-menu {
  padding: 0;
  margin: 8px 0;
}
footer ul.footer-menu li {
  list-style-type: none;
  display: inline-block;
}
footer ul.footer-menu li:after {
  content: '|';
  color: #fff;
  padding: 0 3px;
  text-align: center;
}
footer ul.footer-menu li.last:after {
  content: '';
  padding: 0;
}
footer ul.footer-menu li a {
  color: #fff !important;
}
footer ul.footer-menu li a:hover {
  text-decoration: none;
  color: #fc3868 !important;
  background-color: transparent !important;
  padding: 0;
}
footer ul.social-media-icon {
  padding: 0;
  margin: 0;
}
footer ul.social-media-icon li {
  list-style-type: none;
  display: inline-block;
  margin-right: 10px;
}
footer ul.social-media-icon li:nth-child(2) {
  display: none;
}
footer ul.social-media-icon li a {
  color: #fff !important;
  font-size: 25px;
}
footer .lower-footer {
  font-weight: 300;
  font-size: 13px;
}
footer .copyright {
  display: inline-grid;
  align-self: center;
}
footer .footer-list-menu {
  display: inline-grid;
  align-self: center;
}

@media screen and (max-width: 768px) {
  a .logo {
    text-align: center;
    position: relative;
  }
  .menu-section {
    padding: 20px 0;
  }

  footer ul.footer-menu {
    text-align: center;
  }
  footer .menu-row {
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  a.logo {
    text-align: left;
  }
  .menu-section .menu-row {
    padding: 0;
    text-align: center;
  }

  footer {
    padding: 30px 0;
  }
  footer .menu-row {
    padding: 20px 30px 40px;
  }
  .upper-footer a.logo {
    margin-bottom: 20px;
    text-align: center;
  }
  footer ul.apps-icon {
    padding: 0;
    text-align: center;
  }
  footer ul.footer-menu {
    text-align: center;
  }
}
