.button-wrapper a {
  display: inline-block;
  vertical-align: middle;
}

header {
  background-color: #000;
  padding: 20px 0;
  color: #fff;
}

.desktop-header {
  padding: 20px;
}

.header-logo {
  display: inline-flex;
  align-self: center;
}

a.logo {
  cursor: pointer;
  display: block;
  text-decoration: none;
}
a.logo:hover {
  text-decoration: none;
}

a.logo img.logo-img {
  max-width: 40px;
  margin-right: 15px;
}
a.logo img.logo-word {
  max-width: 117px;
}

a.menu {
  margin-right: 25px;
  color: #fff !important;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
}

a.menu i {
  margin-right: 15px;
  color: #e71f4b;
}

.navbar {
  background-color: #232323;
  padding-top: 10px;
}

.menu-section {
  background-color: #232323;
  padding: 50px 0 60px;
  position: absolute;
  width: 100%;
  z-index: 992;
  position: fixed;
  /* margin-top: 12px; */
}
#menu-section.hide {
  display: none;
}
#menu-section.show {
  display: block;
}
.menu-section .menu-row {
  padding: 0 30px;
}
.menu-row ul {
  padding: 0;
}
.menu-row ul li {
  padding: 10px 0;
  list-style-type: none;
}
.menu-row ul li.title-header {
  color: #fc3868;
  list-style-type: none;
}
.menu-row a {
  color: #fff;
}
.menu-row a:hover {
  text-decoration: none;
  /* color: #fff; */
  background-color: #fc3868;
  padding: 3px;
}
.menu-row a.download:hover {
  text-decoration: none;
  background-color: transparent;
  padding: 0;
}
