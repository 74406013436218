/* Preview-image */
.preview-frame4 {
  margin: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  padding-top: 10px;
  /* float: left; */
  text-align: center;
}

.preview-frame4 img {
  width: 150px;
  height: 150px;
  margin: 10px;
}

.preview-frame4 .pdf-icon {
  color: red;
  display: inline-block;
  margin-top: 8px;
  font-size: 40px;
  position: relative;
  left: 7px;
}

.preview-frame4 .file-pdf {
  display: inline-block;
  margin: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  border-radius: 50%;
  padding: 20px;
  width: 100px;
  height: 100px;
}

.preview-frame4 .file-caption {
  display: block;
  text-align: center;
  padding-top: 4px;
  font-size: 11px;
  color: #777;
  /* margin-bottom: 20px; */
}

.preview-frame4 .file-name {
  display: block;
  text-align: center;
  padding-top: 4px;
  color: #777;
  margin-bottom: 10px;
}

.preview-frame4 .file-info {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  height: 15px;
  margin: auto;
}

.preview-frame4 .remove-icon {
  color: red;
  float: right;
  margin-top: 8px;
  margin-bottom: px;
  width: 16px;
  height: 16px;
  font-size: 20px;
}